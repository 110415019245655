body{
    font-family: Roboto;
    padding: 0;
    margin: 0;
}

.menuBeak:after{
    content:'';
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -5px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right:10px solid #f5f5f5;
    z-index: 2;
}

.disabledLink {
    pointer-events: none;
}